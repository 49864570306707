function isPluralType(val) {
  return val.one !== undefined;
}
const formatDistanceLocale = {
  lessThanXSeconds: {
    present: {
      one: "manj kot {{count}} sekunda",
      two: "manj kot {{count}} sekundi",
      few: "manj kot {{count}} sekunde",
      other: "manj kot {{count}} sekund"
    },
    past: {
      one: "manj kot {{count}} sekundo",
      two: "manj kot {{count}} sekundama",
      few: "manj kot {{count}} sekundami",
      other: "manj kot {{count}} sekundami"
    },
    future: {
      one: "manj kot {{count}} sekundo",
      two: "manj kot {{count}} sekundi",
      few: "manj kot {{count}} sekunde",
      other: "manj kot {{count}} sekund"
    }
  },
  xSeconds: {
    present: {
      one: "{{count}} sekunda",
      two: "{{count}} sekundi",
      few: "{{count}} sekunde",
      other: "{{count}} sekund"
    },
    past: {
      one: "{{count}} sekundo",
      two: "{{count}} sekundama",
      few: "{{count}} sekundami",
      other: "{{count}} sekundami"
    },
    future: {
      one: "{{count}} sekundo",
      two: "{{count}} sekundi",
      few: "{{count}} sekunde",
      other: "{{count}} sekund"
    }
  },
  halfAMinute: "pol minute",
  lessThanXMinutes: {
    present: {
      one: "manj kot {{count}} minuta",
      two: "manj kot {{count}} minuti",
      few: "manj kot {{count}} minute",
      other: "manj kot {{count}} minut"
    },
    past: {
      one: "manj kot {{count}} minuto",
      two: "manj kot {{count}} minutama",
      few: "manj kot {{count}} minutami",
      other: "manj kot {{count}} minutami"
    },
    future: {
      one: "manj kot {{count}} minuto",
      two: "manj kot {{count}} minuti",
      few: "manj kot {{count}} minute",
      other: "manj kot {{count}} minut"
    }
  },
  xMinutes: {
    present: {
      one: "{{count}} minuta",
      two: "{{count}} minuti",
      few: "{{count}} minute",
      other: "{{count}} minut"
    },
    past: {
      one: "{{count}} minuto",
      two: "{{count}} minutama",
      few: "{{count}} minutami",
      other: "{{count}} minutami"
    },
    future: {
      one: "{{count}} minuto",
      two: "{{count}} minuti",
      few: "{{count}} minute",
      other: "{{count}} minut"
    }
  },
  aboutXHours: {
    present: {
      one: "približno {{count}} ura",
      two: "približno {{count}} uri",
      few: "približno {{count}} ure",
      other: "približno {{count}} ur"
    },
    past: {
      one: "približno {{count}} uro",
      two: "približno {{count}} urama",
      few: "približno {{count}} urami",
      other: "približno {{count}} urami"
    },
    future: {
      one: "približno {{count}} uro",
      two: "približno {{count}} uri",
      few: "približno {{count}} ure",
      other: "približno {{count}} ur"
    }
  },
  xHours: {
    present: {
      one: "{{count}} ura",
      two: "{{count}} uri",
      few: "{{count}} ure",
      other: "{{count}} ur"
    },
    past: {
      one: "{{count}} uro",
      two: "{{count}} urama",
      few: "{{count}} urami",
      other: "{{count}} urami"
    },
    future: {
      one: "{{count}} uro",
      two: "{{count}} uri",
      few: "{{count}} ure",
      other: "{{count}} ur"
    }
  },
  xDays: {
    present: {
      one: "{{count}} dan",
      two: "{{count}} dni",
      few: "{{count}} dni",
      other: "{{count}} dni"
    },
    past: {
      one: "{{count}} dnem",
      two: "{{count}} dnevoma",
      few: "{{count}} dnevi",
      other: "{{count}} dnevi"
    },
    future: {
      one: "{{count}} dan",
      two: "{{count}} dni",
      few: "{{count}} dni",
      other: "{{count}} dni"
    }
  },
  // no tenses for weeks?
  aboutXWeeks: {
    one: "približno {{count}} teden",
    two: "približno {{count}} tedna",
    few: "približno {{count}} tedne",
    other: "približno {{count}} tednov"
  },
  // no tenses for weeks?
  xWeeks: {
    one: "{{count}} teden",
    two: "{{count}} tedna",
    few: "{{count}} tedne",
    other: "{{count}} tednov"
  },
  aboutXMonths: {
    present: {
      one: "približno {{count}} mesec",
      two: "približno {{count}} meseca",
      few: "približno {{count}} mesece",
      other: "približno {{count}} mesecev"
    },
    past: {
      one: "približno {{count}} mesecem",
      two: "približno {{count}} mesecema",
      few: "približno {{count}} meseci",
      other: "približno {{count}} meseci"
    },
    future: {
      one: "približno {{count}} mesec",
      two: "približno {{count}} meseca",
      few: "približno {{count}} mesece",
      other: "približno {{count}} mesecev"
    }
  },
  xMonths: {
    present: {
      one: "{{count}} mesec",
      two: "{{count}} meseca",
      few: "{{count}} meseci",
      other: "{{count}} mesecev"
    },
    past: {
      one: "{{count}} mesecem",
      two: "{{count}} mesecema",
      few: "{{count}} meseci",
      other: "{{count}} meseci"
    },
    future: {
      one: "{{count}} mesec",
      two: "{{count}} meseca",
      few: "{{count}} mesece",
      other: "{{count}} mesecev"
    }
  },
  aboutXYears: {
    present: {
      one: "približno {{count}} leto",
      two: "približno {{count}} leti",
      few: "približno {{count}} leta",
      other: "približno {{count}} let"
    },
    past: {
      one: "približno {{count}} letom",
      two: "približno {{count}} letoma",
      few: "približno {{count}} leti",
      other: "približno {{count}} leti"
    },
    future: {
      one: "približno {{count}} leto",
      two: "približno {{count}} leti",
      few: "približno {{count}} leta",
      other: "približno {{count}} let"
    }
  },
  xYears: {
    present: {
      one: "{{count}} leto",
      two: "{{count}} leti",
      few: "{{count}} leta",
      other: "{{count}} let"
    },
    past: {
      one: "{{count}} letom",
      two: "{{count}} letoma",
      few: "{{count}} leti",
      other: "{{count}} leti"
    },
    future: {
      one: "{{count}} leto",
      two: "{{count}} leti",
      few: "{{count}} leta",
      other: "{{count}} let"
    }
  },
  overXYears: {
    present: {
      one: "več kot {{count}} leto",
      two: "več kot {{count}} leti",
      few: "več kot {{count}} leta",
      other: "več kot {{count}} let"
    },
    past: {
      one: "več kot {{count}} letom",
      two: "več kot {{count}} letoma",
      few: "več kot {{count}} leti",
      other: "več kot {{count}} leti"
    },
    future: {
      one: "več kot {{count}} leto",
      two: "več kot {{count}} leti",
      few: "več kot {{count}} leta",
      other: "več kot {{count}} let"
    }
  },
  almostXYears: {
    present: {
      one: "skoraj {{count}} leto",
      two: "skoraj {{count}} leti",
      few: "skoraj {{count}} leta",
      other: "skoraj {{count}} let"
    },
    past: {
      one: "skoraj {{count}} letom",
      two: "skoraj {{count}} letoma",
      few: "skoraj {{count}} leti",
      other: "skoraj {{count}} leti"
    },
    future: {
      one: "skoraj {{count}} leto",
      two: "skoraj {{count}} leti",
      few: "skoraj {{count}} leta",
      other: "skoraj {{count}} let"
    }
  }
};
function getFormFromCount(count) {
  switch (count % 100) {
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
    case 4:
      return "few";
    default:
      return "other";
  }
}
export const formatDistance = (token, count, options) => {
  let result = "";
  let tense = "present";
  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      tense = "future";
      result = "čez ";
    } else {
      tense = "past";
      result = "pred ";
    }
  }
  const tokenValue = formatDistanceLocale[token];
  if (typeof tokenValue === "string") {
    result += tokenValue;
  } else {
    const form = getFormFromCount(count);
    if (isPluralType(tokenValue)) {
      result += tokenValue[form].replace("{{count}}", String(count));
    } else {
      result += tokenValue[tense][form].replace("{{count}}", String(count));
    }
  }
  return result;
};
export enum WeekDay {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum WeekDayNames {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export const WEEK_DAYS_ARRAY = [
  WeekDayNames.Monday,
  WeekDayNames.Tuesday,
  WeekDayNames.Wednesday,
  WeekDayNames.Thursday,
  WeekDayNames.Friday,
  WeekDayNames.Saturday,
  WeekDayNames.Sunday,
];

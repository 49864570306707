import { signal } from '@angular/core';
import { catchError, Observable, of, take, throwError } from 'rxjs';

export class Load<T> {
  isLoading = false;
  hasError = false;
  errorMessage = '';
  hasSubmitted = false;
  action$: Observable<T> | undefined;
  data = signal<T | undefined>(undefined);

  constructor(defaultData: T | undefined = undefined) {
    this.data.set(defaultData);
  }

  perform(obs$: Observable<T>, onError?: () => void): void {
    this.isLoading = true;
    this.hasError = false;
    this.action$ = obs$;
    this.hasSubmitted = true;
    this.errorMessage = '';
    this._load(onError).pipe(take(1)).subscribe();
  }

  perform$(obs$: Observable<T>): Observable<T> {
    this.isLoading = true;
    this.hasError = false;
    this.action$ = obs$;
    this.errorMessage = '';
    return this._load().pipe(take(1));
  }

  private _load(onError?: () => void): Observable<T> {
    return new Observable((observer) => {
      setTimeout(() => (this.isLoading = false), 4000);

      if (!this.action$) {
        observer.complete();
        return;
      }

      this.action$
        .pipe(
          take(1),
          catchError((err) => {
            onError?.();
            this.isLoading = false;
            this.hasError = true;
            this.hasSubmitted = false;
            this.errorMessage = err.message || 'An unknown error occurred';
            observer.error(err);
            observer.complete();
            return throwError(() => new Error('ERROR'));
          })
        )
        .subscribe((data) => {
          this.data.set(data);
          this.hasSubmitted = true;
          this.hasError = false;
          this.isLoading = false;
          observer.next(data);
          observer.complete();
        });
    });
  }
}

import { format } from 'date-fns';
import * as locales from 'date-fns/locale';
import { Language } from '../enums';

export enum DateFormat {
  Time = 'HH:mm',
  FullDate = 'yyyy-MM-dd',
  FullDateWithTime = 'yyyy-MM-dd HH:mm',
  FullDateWithTimeWithTimezone = 'yyyy-MM-ddTHH:mm',
  FullDateWithTimeAndSeconds = 'yyyy-MM-dd HH:mm:ss',
  ShortWeek = 'EEE',
  FriendlyDate = 'EEE dd MMM',
  FriendlyDateWithTime = 'EEE dd MMM HH:mm',
}

export const formatDate = (
  date: Date | string,
  dateFormat: DateFormat,
  locale = Language.Swedish
) => {
  const _date = new Date(date);
  return format(_date, dateFormat, {
    locale: getDateFnsLocaleByLanguage(locale),
  });
};

export function getDateFnsLocaleByLanguage(language: Language) {
  return (locales as any)[language];
}

export const toLocal = (date: Date | string): Date => {
  date = new Date(date);
  const localOffset = new Date().getTimezoneOffset() * 60 * 1000; // Get local timezone offset in milliseconds
  const localTime = new Date(date.getTime() - localOffset); // Subtract the offset to get local time
  return localTime;
};

export const toUtc = (date: Date | string): Date => {
  date = new Date(date);
  const localOffset = new Date().getTimezoneOffset() * 60 * 1000; // Get local timezone offset in milliseconds
  const localTime = new Date(date.getTime() + localOffset); // Add the offset to get local time
  return localTime;
};

export const toBackendFormat = (date: Date | string): string => {
  date = new Date(date);
  const utc = toUtc(date);
  const dateString = formatDate(utc, DateFormat.FullDateWithTime);
  return `${dateString.replace(' ', 'T')}`;
};

export const toLocalDate = (date: Date | string): Date => {
  const local = toLocal(date);
  return local;
};
